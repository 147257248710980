<div
        #linkedAccountsModal="bs-modal"
        [config]="{ keyboard: false }"
        appBsModal
        aria-hidden="true"
        aria-labelledby="myLargeModalLabel"
        class="modal fade"
        role="dialog"
        tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ 'LinkedAccounts' | localize }}</span>
                </h4>
                <button (click)="manageLinkedAccounts()" class="btn btn-primary pull-right">
                    <i class="fa-duotone fa-plus btn-md-icon"></i>
                    <span class="d-none d-md-inline-block">
                        {{ 'LinkNewAccount' | localize }}
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <!--<Primeng-TurboTable-Start>-->
                <div [busyIf]="primengTableHelper.isLoading" class="primeng-datatable-container">
                    <p-table
                        #dataTable
                        (onLazyLoad)="getLinkedUsers($event)"
                        [lazy]="true"
                        [paginator]="false"
                        [value]="primengTableHelper.records"
                        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        sortMode="multiple"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 20%">
                                    {{ 'Actions' | localize }}
                                </th>
                                <th pSortableColumn="userName" style="width: 65%">
                                    {{ 'UserName' | localize }}
                                    <p-sortIcon field="userName"></p-sortIcon>
                                </th>
                                <th style="width: 15%">
                                    {{ 'Delete' | localize }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template let-record="$implicit" pTemplate="body">
                            <tr>
                                <td style="width: 20%">
                                    <button (click)="switchToUser(record)" class="btn btn-sm btn-primary">
                                        <i class="fa-duotone fa-sign-in-alt"></i>
                                        {{ 'LogIn' | localize }}
                                    </button>
                                </td>
                                <td style="width: 65%">
                                    <span class="p-column-title">{{ 'UserName' | localize }}</span>
                                    {{ getShownLinkedUserName(record) }}
                                </td>
                                <td style="width: 15%">
                                    <button
                                            (click)="deleteLinkedUser(record)"
                                            class="btn btn-outline-danger btn-sm btn-icon"
                                    >
                                        <i [attr.aria-label]="l('Delete')" class="fa-duotone fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="primeng-no-data">
                        {{ 'NoData' | localize }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator
                                #paginator
                                (onPageChange)="getLinkedUsers($event)"
                                [currentPageReportTemplate]="
                                'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                            "
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                [showCurrentPageReport]="true"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                        ></p-paginator>
                    </div>
                </div>
                <!--<Primeng-TurboTable-End>-->
            </div>
            <div class="modal-footer">
                <button (click)="close()" class="btn btn-light-primary fw-bold pull-right" type="button">
                    {{ 'Close' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
<linkAccountModal #linkAccountModal (modalSave)="getLinkedUsers()"></linkAccountModal>
