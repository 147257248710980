<div [@routerTransition]>
    <sub-header [title]="'Notifications' | localize">
        <div role="actions">
            <button (click)="openNotificationSettingsModal()" class="btn btn-success">
                <i class="fa-duotone fa-angle-right btn-md-icon"></i>
                <span class="d-none d-md-inline-block">
                    {{ 'NotificationSettings' | localize }}
                </span>
            </button>
            <button (click)="setAllNotificationsAsRead()" class="btn btn-primary">
                <i class="fa-duotone fa-check btn-md-icon"></i>
                <span class="d-none d-md-inline-block">
                    {{ 'SetAllAsRead' | localize }}
                </span>
            </button>
        </div>
    </sub-header>
    <div [class]="containerClass">
        <div class="card card-custom">
            <div class="card-body">
                <div class="form">
                    <div class="row mb-4">
                        <div class="col-xl-6">
                            <input
                                    #drp="bsDaterangepicker"
                                    [(date)]="dateRange"
                                    bsDaterangepicker
                                    class="form-control"
                                    dateRangePickerLuxonModifier
                                    id="StartEndRange"
                                    name="StartEndRange"
                                    readonly="readonly"
                            />
                        </div>
                        <div class="col-xl-6">
                            <div class="mb-5">
                                <select
                                    #TargetValueFilterSelectionCombobox
                                    [(ngModel)]="readStateFilter"
                                    class="form-control"
                                    name="readStateFilter"
                                >
                                    <option value="ALL">{{ 'All' | localize }}</option>
                                    <option value="UNREAD">{{ 'Unread' | localize }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 text-end">
                            <div class="mb-5">
                                <button (click)="deleteNotifications()" class="btn btn-danger">
                                    <i class="la la-times"></i>
                                    {{ 'DeleteListedNotifications' | localize }}
                                </button>
                                <button (click)="getNotifications()" class="btn btn-primary">
                                    <i class="fa-duotone fa-sync"></i>
                                    {{ 'Refresh' | localize }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div [busyIf]="primengTableHelper.isLoading" class="col primeng-datatable-container">
                        <p-table
                            #dataTable
                            (onLazyLoad)="getNotifications($event)"
                            [lazy]="true"
                            [paginator]="false"
                            [value]="primengTableHelper.records"
                            rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 100px; text-align: center">
                                        {{ 'Actions' | localize }}
                                    </th>
                                    <th style="width: 80px; text-align: center">{{ 'Severity' | localize }}</th>
                                    <th>
                                        {{ 'Notification' | localize }}
                                    </th>
                                    <th style="width: 200px; text-align: center">
                                        {{ 'CreationTime' | localize }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template let-record="$implicit" pTemplate="body">
                                <tr>
                                    <td style="width: 130px; justify-content: start;">
                                        <button
                                                (click)="setAsRead(record)"
                                                [attr.title]="!isRead(record) ? l('SetAsRead') : ''"
                                                [disabled]="isRead(record)"
                                                class="btn btn-sm btn-icon btn-primary"
                                        >
                                            <i
                                                    *ngIf="isRead(record)"
                                                    [attr.aria-label]="l('Read')"
                                                    class="fa-duotone fa-check"
                                            ></i>
                                            <i
                                                    *ngIf="!isRead(record)"
                                                    [attr.aria-label]="l('Unread')"
                                                    class="fa-duotone fa-circle-notch"
                                            ></i>
                                        </button>
                                        <button
                                                (click)="deleteNotification(record)"
                                                [attr.title]="l('Delete')"
                                                class="btn btn-sm btn-icon btn-danger"
                                        >
                                            <i [attr.aria-label]="l('Delete')" class="la la-times"></i>
                                        </button>
                                    </td>
                                    <td style="text-align: center">
                                        <i
                                                class="{{ record.formattedNotification.icon }} {{
                                                record.formattedNotification.iconFontClass
                                            }} fa-2x"
                                                tooltip="{{
                                                getNotificationTextBySeverity(record.formattedNotification.severity)
                                            }}"
                                        ></i>
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{ 'Notification' | localize }}</span>
                                        <a
                                                *ngIf="record.formattedNotification.url"
                                                class="{{ getRowClass(record) }}"
                                                href="{{ record.formattedNotification.url }}"
                                        >
                                            {{ truncateString(record.formattedNotification.text, 120) }}
                                        </a>
                                        <span
                                                *ngIf="!record.formattedNotification.url"
                                                class="{{ getRowClass(record) }}"
                                                title="{{ record.formattedNotification.text }}"
                                        >
                                            {{ truncateString(record.formattedNotification.text, 120) }}
                                        </span>
                                    </td>
                                    <td style="text-align: center">
                                        <span
                                                class="{{ getRowClass(record) }}"
                                                title="{{ record.notification.creationTime | luxonFormat: 'DDDD t' }}"
                                        >
                                            {{ fromNow(record.notification.creationTime) }}
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="primeng-no-data">
                            {{ 'NoData' | localize }}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator
                                    #paginator
                                    (onPageChange)="getNotifications($event)"
                                    [currentPageReportTemplate]="
                                    'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                "
                                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                    [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                    [showCurrentPageReport]="true"
                                    [totalRecords]="primengTableHelper.totalRecordsCount"
                            ></p-paginator>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>
