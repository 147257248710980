import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { UsersType } from '@shared/service-proxies/service-proxies';

@Injectable()
export class AppNavigationService {
  constructor(
    private _permissionCheckerService: PermissionCheckerService,
    private _appSessionService: AppSessionService
  ) { }

  //   STUDENT SIDE NAVIGATIONS
  getMenu(): AppMenu {
    if (this._appSessionService.user.usersType === UsersType.Student) {
      return new AppMenu('MainMenu', 'MainMenu', [
        // new AppMenuItem(
        //     'Student Dashboard',
        //     '',
        //     'fa-duotone fa-gauge-max',
        //     '/app/main/studentProfile/studentDashboard'
        // ),
        new AppMenuItem('Academics', '', 'fa-duotone fa-lightbulb-on', '/app/main/studentProfile/academics'),
        new AppMenuItem('Learning', '', 'fa-duotone fa-memo-circle-check', '/app/main/studentProfile/learning'),
        new AppMenuItem(
          'Learning Center',
          '',
          'fa-duotone fa-graduation-cap',
          '/app/main/studentProfile/studentcourse'
        ),
        new AppMenuItem('Exam', '', 'fa-duotone fa-hand-holding-dollar', '/app/main/studentProfile/exam'),
        new AppMenuItem('Fee', '', 'fa-duotone fa-hand-holding-dollar', '/app/main/studentProfile/studentfeePayment'),
        // new AppMenuItem('Mark Sheet', '', 'fa-duotone fa-receipt', '/app/main/studentProfile/markSheet'),
        new AppMenuItem('Attendance', '', 'fa-duotone fa-calendar-plus', '/app/main/studentProfile/attendance'),
        new AppMenuItem('Progress Report', '', 'fa-duotone fa-receipt', '/app/main/studentProfile/progressReport'),
        new AppMenuItem('Teacher Review', '', 'fa-duotone fa-circle-check', '/app/main/studentProfile/teacherReview'),
        new AppMenuItem('Assignment', '', 'fa-duotone fa-memo-pad', '/app/main/studentProfile/assignment'),
        new AppMenuItem('Homework', '', 'fa-duotone fa-memo', '/app/main/studentProfile/homework'),
        new AppMenuItem(
          'Leave Note',
          '',
          'fa-duotone fa-memo-circle-info',
          '/app/main/studentProfile/studentLeaveNote'
        ),
        new AppMenuItem('Library', '', 'fa-duotone fa-books', '/app/main/studentProfile/library'),
        // new AppMenuItem(
        //     'Course Plan',
        //     '',
        //     'fa-duotone fa-calendar-exclamation',
        //     '/app/main/studentProfile/coursePlan'
        // ),
        // new AppMenuItem('Documents', '', 'fa-duotone fa-folder', '/app/main/studentProfile/studentDocument'),
        // new AppMenuItem(
        //     'Bus Route',
        //     '',
        //     'fa-duotone fa-map-location-dot',
        //     '/app/main/studentProfile/studentBusRoute'
        // ),
        // new AppMenuItem('Profile', '', 'fa-duotone fa-user', '/app/main/studentProfile/studentProfile'),
        new AppMenuItem('Online Class', '', 'fa-duotone fa-desktop', '/app/main/studentProfile/studentOnlineClass'),
        new AppMenuItem('Event', '', 'fa-duotone fa-calendar-star', '/app/main/studentProfile/studentEvent'),
        new AppMenuItem('Gallery', '', 'fa-duotone fa-image', '/app/main/studentProfile/studentGallery'),
        new AppMenuItem('Quiz', '', 'fa-duotone fa-lightbulb-on', '/app/main/studentProfile/quiz'),
        new AppMenuItem('publishArticles', '', 'fa-duotone fa-newspaper', '/app/main/studentProfile/publishArticles'),
        new AppMenuItem('Book Hold', '', 'fa-duotone fa-book-open-reader', '/app/main/studentProfile/Bookhold'),
        // new AppMenuItem('Objectives Exam', '', 'fa-duotone fa-box-check', '/app/main/studentProfile/objectives'),
      ]);

      //  TEACHERS SIDE NAVIGATIONS
    } else if (this._appSessionService.user.usersType === UsersType.Teacher) {
      return new AppMenu('MainMenu', 'MainMenu', [
        // new AppMenuItem('Teacher Dashboard', '', 'fa-duotone fa-wallet', '/app/main/teacher/salaryStatus'),
        new AppMenuItem(
          'Academy',
          '',
          'fa-duotone fa-memo-circle-check',
          '',
          [],
          [
            new AppMenuItem(
              'Academic Calender',
              '',
              'fa-duotone fa-calendar-days',
              '/app/main/teacher/academic-calender'
            ),
            new AppMenuItem(
              'Teacher Class Schedule',
              '',
              'fa-duotone fa-memo-circle-check',
              '/app/main/teacher/teacher-class-schedule'
            ),

            new AppMenuItem(
              'CMS',
              '',
              'fa-duotone fa-users-gear',
              '',
              [],
              [
                new AppMenuItem('Notice', '', 'fa-duotone fa-memo-circle-check', '/app/main/teacher/notice'),
                new AppMenuItem('Event', '', 'fa-duotone fa-calendar', '/app/main/teacher/teacherEvent'),
                new AppMenuItem('Article', '', 'fa-duotone fa-newspaper', '/app/main/teacher/article'),

                new AppMenuItem('Teacher Review', '', 'fa-duotone fa-star-sharp', '/app/main/teacher/teacherReview'),
              ]
            ),
          ]
        ),

        new AppMenuItem(
          'LMS',
          '',
          'fa-duotone fa-graduation-cap',
          '',
          [],
          [
            new AppMenuItem('Assigment', '', 'fa-duotone fa-boxes-stacked', '/app/main/teacher/assignment'),
            new AppMenuItem('Homework', '', 'fa-duotone fa-money-check-pen', '/app/main/teacher/homework'),

            new AppMenuItem('Course Category', '', 'fa-duotone fa-layer-group', '/app/main/teacher/courseCate'),

            new AppMenuItem('Course Master', '', 'fa-duotone fa-books', '/app/main/teacher/course'),


            new AppMenuItem('Course Unit', '', 'fa-duotone fa-pen-field', '/app/main/teacher/courseUnit'),
            new AppMenuItem('Online Class', '', 'fa-duotone fa-gear', '/app/main/teacher/onlineClass'),
            new AppMenuItem('Lecture Note', '', 'fa-duotone fa-note', '/app/main/teacher/lectureNote'),
            new AppMenuItem(
              'Online Meetings',
              '',
              'fa-duotone fa-people-roof',
              '/app/main/teacher/teacher-online-meeting'
            ),
          ]
        ),

        //new AppMenuItem('HRMS', '', 'fa-duotone fa-memo-circle-check', '/app/main/teacher/hrms'),
        new AppMenuItem(
          'Exam',
          '',
          'fa-duotone fa-file-pen',
          '',
          [],
          [
            new AppMenuItem('Exam Mark Entry', '', 'fa-duotone fa-gear', '/app/main/teacher/marksEntry'),
            new AppMenuItem('Exam Attendance', '', 'fa-duotone fa-calendar-plus', '/app/main/teacher/examAttendance'),
            new AppMenuItem('Objective Exam', '', 'fa-duotone fa-pen-field', '/app/main/teacher/teacherObjectiveExam'),
          ]
        ),

        new AppMenuItem('Quiz', '', 'fa-duotone fa-question', '/app/main/teacher/quiz'),
        new AppMenuItem('Library', '', 'fa-duotone fa-books', '/app/main/teacher/library'),

        new AppMenuItem('Book Hold', '', 'fa-duotone fa-hand-holding', '/app/main/teacher/bookHold'),
        new AppMenuItem('Student Leave Note', '', 'fa-duotone fa-notes', '/app/main/teacher/studentLeave'),
        new AppMenuItem('Teacher-Profile', '', 'fa-duotone fa-address-card', '/app/main/teacher/teacher-profile'),
      ]);

      // PARENTS SIDE NAVIGATION
    } else if (this._appSessionService.user.usersType === UsersType.Parent) {
      return new AppMenu('MainMenu', 'MainMenu', [
        new AppMenuItem(
          'Academy',
          '',
          'fa-duotone fa-memo-circle-check',
          '',
          [],
          [
            new AppMenuItem('Calender', '', 'fa-duotone fa-calendar-days', '/app/main/parent/calender'),

            new AppMenuItem(
              'CMS',
              '',
              'fa-duotone fa-users-gear',
              '',
              [],
              [
                new AppMenuItem('Notice', '', 'fa-duotone fa-memo-circle-check', '/app/main/parent/parentNotice'),
                new AppMenuItem('Article', '', 'fa-duotone fa-newspaper', '/app/main/parent/par-article'),

                new AppMenuItem('Teacher Review', '', 'fa-duotone fa-layer-group', '/app/main/parent/teacher-review'),
              ]
            ),
          ]
        ),
        new AppMenuItem('Fee', '', 'fa-duotone fa-money-bill', '/app/main/parent/fee'),
        new AppMenuItem('Result', '', 'fa-duotone fa-square-poll-horizontal', '/app/main/parent/result'),
        new AppMenuItem('Attendance', '', 'fa-duotone fa-check', '/app/main/parent/attendance'),
        new AppMenuItem('Leave Note', '', 'fa-duotone fa-note', '/app/main/parent/leave-request'),
      ]);
    } else {
      //   ADMIN SIDE NAVIGATIONS
      return new AppMenu('MainMenu', 'MainMenu', [
        new AppMenuItem(
          'Dashboard',
          'Pages.Administration.Host.Dashboard',
          'fa-duotone fa-house',
          '/app/admin/hostDashboard'
        ),
        new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-duotone fa-house', '/app/main/dashboard'),
        new AppMenuItem('Tenants', 'Pages.Tenants', 'pi pi-users', '/app/admin/tenants'),
        new AppMenuItem('Editions', 'Pages.Editions', 'pi pi-book', '/app/admin/editions'),
        new AppMenuItem(
          'Administration',
          'Pages.AcademicAdministration',
          'fa-duotone fa-gear',
          '',
          [],

          [
            new AppMenuItem(
              'Academy',
              'Pages.AcademicAdministration',
              'fa-duotone fa-graduation-cap',
              '',
              [],
              [
                new AppMenuItem('Batch', 'Pages.Batchs', 'fa-duotone fa-list-timeline', '/app/main/academy/batch'),
                new AppMenuItem('Course', 'Pages.AccountGroups', 'fa-duotone fa-house', '/app/main/academy/program'),
                new AppMenuItem('Class', 'Pages.SchClasses', 'fa-duotone fa-screen-users', '/app/main/academy/class'),
                new AppMenuItem('Section', 'Pages.Sections', 'fa-duotone fa-section', '/app/main/academy/section'),
                new AppMenuItem(
                  'Shift',
                  'Pages.Shifts',
                  'fa-duotone fa-arrow-right-arrow-left',
                  '/app/main/academy/shift'
                ),
                new AppMenuItem('Subject', 'Pages.Subjects', 'fa-duotone fa-book-open', '/app/main/academy/subject'),
                new AppMenuItem(
                  'Class Subject Mapping',
                  'Pages.ClassSubjectMappings',
                  'fa-duotone fa-sitemap',
                  '/app/main/academy/classSubjectMapping'
                ),
                new AppMenuItem(
                  'Student Subject Mapping',
                  'Pages.StudentSubjectMappings',
                  'fa-duotone fa-boxes-stacked',
                  '/app/main/academy/studentSubjectMapping'
                ),
                new AppMenuItem('Grade', 'Pages.Grades', 'fa-duotone fa-file-chart-column', '/app/main/academy/grade'),
                new AppMenuItem(
                  'Division Master',
                  'Pages.Divisions',
                  'fa-duotone fa-signal-bars',
                  '/app/main/academy/divisionMaster'
                ),
                new AppMenuItem(
                  'Disruptions Catagory',
                  'Pages.Divisions',
                  'fa-duotone fa-signal-bars',
                  '/app/main/academy/disruptions-catagory'
                ),
                new AppMenuItem(
                  'Academy Calander',
                  'Pages.AccountGroups',
                  'fa-duotone fa-house',
                  '/app/main/academy/academy-calander'
                ),
                // new AppMenuItem(
                //   'Holiday Calander',
                //   'Pages.AccountGroups',
                //   'fa-duotone fa-house',
                //   '/app/main/academy/holiday-calander'
                // ),
              ]
            ),
            new AppMenuItem(
              'Class Management',
              'Pages.ClassManagement',
              'fa-duotone fa-people-roof',
              '',
              [],
              [
                new AppMenuItem('Period', 'Pages.FeeHeads', 'fa-duotone fa-timer', '/app/main/administration/period'),
                new AppMenuItem(
                  'Class Period',
                  'Pages.ClasssPeriods',
                  'fa-duotone fa-computer-classic',
                  '/app/main/administration/classPeriod'
                ),
                new AppMenuItem(
                  'Teacher Subject Mapping',
                  'Pages.TeacherSubjectMappings',
                  'fa-duotone fa-people-roof',
                  '/app/main/administration/teacher-subject-mapping'
                ),
                new AppMenuItem(
                  'Class Shedule',
                  'Pages.ClassSchedules',
                  'fa-duotone fa-calendar-days',
                  '/app/main/administration/classShedule'
                ),
              ]
            ),
            new AppMenuItem(
              'FrontDesk',
              'Pages.FrontDesk',
              'fa-duotone fa-bring-front',
              '',
              [],
              [
                new AppMenuItem('Guest', 'Pages.FeeHeads', 'fa-duotone fa-user', '/app/main/administration/guest'),
                new AppMenuItem(
                  'Visitors',
                  'Pages.FeeHeads',
                  'fa-duotone fa-users',
                  '/app/main/administration/visitors'
                ),
                new AppMenuItem(
                  'Employee Application',
                  'Pages.EmployeeApplications',
                  'fa-duotone fa-pen-field',
                  '/app/main/administration/EmployeeApplication'
                ),
                new AppMenuItem(
                  'Enquiry Form',
                  'Pages.EnquiryForms',
                  'fa-duotone fa-memo',
                  '/app/main/administration/EnquiryForms'
                ),
                new AppMenuItem(
                  'Feed Back',
                  'Pages.Feedbacks',
                  'fa-duotone fa-comment-lines',
                  '/app/main/administration/FeedBack'
                ),
              ]
            ),
            new AppMenuItem(
              'Boarder',
              'Pages.Boarder',
              'fa-duotone fa-border-outer',
              '',
              [],
              [
                new AppMenuItem(
                  'Building Master',
                  'Pages.Buildings',
                  'fa-duotone fa-user',
                  '/app/main/administration/buildingMaster'
                ),
                new AppMenuItem(
                  'Boarder Master',
                  'Pages.Boarders',
                  'fa-duotone fa-user-secret',
                  '/app/main/administration/borders'
                ),
                new AppMenuItem(
                  'Boarders Mapping',
                  'Pages.BoarderMappings',
                  'fa-duotone fa-border-all',
                  '/app/main/administration/boardersMapping'
                ),
              ]
            ),
            new AppMenuItem(
              'Award',
              'Pages.Award',
              'fa-duotone fa-award',
              '',

              [],
              [
                new AppMenuItem(
                  'Award Categories',
                  'Pages.AwardCategories',
                  'fa-duotone fa-file-certificate',
                  '/app/main/administration/awardCategories'
                ),
                new AppMenuItem(
                  'Award Master',
                  'Pages.Awards',
                  'fa-duotone fa-trophy',
                  '/app/main/administration/award'
                ),

                new AppMenuItem(
                  'Student Awards',
                  'Pages.StudentAwards',
                  'fa-duotone fa-medal',
                  '/app/main/administration/student-awards'
                ),
              ]
            ),
            new AppMenuItem(
              'Lunch',
              'Pages.Lunchs',
              'fa-duotone fa-utensils',
              '',

              [],
              [
                new AppMenuItem(
                  'Lunch Master',
                  'Pages.Lunchs',
                  'fa-duotone fa-plate-utensils',
                  '/app/main/administration/lunchs'
                ),
                new AppMenuItem(
                  'Lunch Mapping',
                  'Pages.LunchMappings',
                  'fa-duotone fa-map',
                  '/app/main/administration/lunchmaping'
                ),
                new AppMenuItem(
                  'Lunch Attendance',
                  'Pages.LunchAttendances',
                  'fa-duotone fa-rainbow',
                  '/app/main/administration/lunchsAttendance'
                ),
                new AppMenuItem(
                  'Lunch Fee',
                  'Pages.LunchFees',
                  'fa-duotone fa-money-bill',
                  '/app/main/administration/lunchfee'
                ),
              ]
            ),
            new AppMenuItem(
              'Transportation',
              'Pages.Transport',
              'fa-duotone fa-taxi-bus',
              '',
              [],
              [
                new AppMenuItem(
                  'Bus Master',
                  'Pages.BusMasters',
                  'fa-duotone fa-duotone fa-bus',
                  '/app/main/transportation/busmaster'
                ),
                new AppMenuItem(
                  'Bus Stop',
                  'Pages.BusStops',
                  'fa-duotone fa-octagon-exclamation',
                  '/app/main/transportation/busStop'
                ),
                new AppMenuItem(
                  'Bus Routine',
                  'Pages.BusRoutines',
                  'fa-duotone fa-calendar-days',
                  '/app/main/transportation/busRoutine'
                ),
                new AppMenuItem(
                  'Bus Transaction Mapping',
                  'Pages.BusTransactionMappings',
                  'fa-duotone fa-screen-users',
                  '/app/main/transportation/busTransactionMapping'
                ),
                new AppMenuItem(
                  'Bus Attendance',
                  'Pages.BusAttendances',
                  'fa-duotone fa-calendar-plus',
                  '/app/main/transportation/busAttendance'
                ),
              ]
            ),
            new AppMenuItem(
              'CMS',
              'Pages.Cms',
              'fa-duotone fa-users-gear',
              '',
              [],
              [
                new AppMenuItem('Article', 'Pages.Articles', 'fa-duotone fa-newspaper', '/app/main/cms/Articles'),
                new AppMenuItem('Notice', 'Pages.Notices', 'fa-duotone fa-bell-exclamation', '/app/main/cms/Notices'),

                new AppMenuItem('Pages', 'Pages.CmsPages', 'fa-duotone fa-page', '/app/main/cms/page'),
                new AppMenuItem(
                  'Banner Categories',
                  'Pages.BannerCategories',
                  'fa-duotone fa-layer-group',
                  '/app/main/cms/bannercategory'
                ),
                new AppMenuItem('Banners', 'Pages.Banners', 'fa-duotone fa-ribbon', '/app/main/cms/banner'),
                new AppMenuItem(
                  'Blog Categories',
                  'Pages.BlogCategories',
                  'fa-duotone fa-layer-group',
                  '/app/main/cms/blogcategory'
                ),
                new AppMenuItem('Blogs', 'Pages.Blogs', 'fa-duotone fa-blog', '/app/main/cms/blog'),
                new AppMenuItem('Events', 'Pages.CmsEvents', 'fa-duotone fa-calendar', '/app/main/cms/event'),
                new AppMenuItem(
                  'Facility',
                  'Pages.CmsFacilities',
                  'fa-duotone fa-house-medical',
                  '/app/main/cms/Facility'
                ),
                new AppMenuItem(
                  'Download',
                  'Pages.CmsDownloads',
                  'fa-duotone fa-circle-down',
                  '/app/main/cms/Download'
                ),
                new AppMenuItem(
                  'Gallery Categories',
                  'Pages.GalleryCategories',
                  'fa-duotone fa-image-polaroid',
                  '/app/main/cms/gallerycategories'
                ),
                new AppMenuItem('Gallery', 'Pages.Gallery', 'fa-duotone fa-image', '/app/main/cms/gallery'),
                new AppMenuItem('TeamMembers', 'Pages.TeamMembers', 'fa-duotone fa-users', '/app/main/cms/teammember'),
                new AppMenuItem('Courses', 'Pages.CmsCourse', 'fa-duotone fa-books', '/app/main/cms/course'),
                new AppMenuItem('Careers', 'Pages.CmsCourse', 'fa-duotone fa-vector-circle', '/app/main/cms/careers'),
                new AppMenuItem('Video Categories', '', 'fa-duotone fa-speaker', '/app/main/cms/CmsVideoCategories'),
                new AppMenuItem('Video', 'Pages.CmsVideos', 'fa-duotone fa-speaker', '/app/main/cms/CmsVideo'),
                new AppMenuItem(
                  'Testimonials',
                  'Pages.CmsCourse',
                  'fa-duotone fa-speaker',
                  '/app/main/cms/testimonials'
                ),
              ]
            ),
          ]
        ),
        new AppMenuItem(
          'Student',
          'Pages.Student',
          'fa-duotone fa-user',
          '',
          [],

          [
            new AppMenuItem(
              'Student Category',
              'Pages.ScStudentCategories',
              'fa-duotone fa-users',
              '/app/main/student/studentCategory'
            ),
            new AppMenuItem(
              'Student Information',
              'Pages.StudentInfos',
              'fa-duotone fa-circle-info',
              '/app/main/student/studentInfo'
            ),
            new AppMenuItem(
              'House Group',
              'Pages.HouseGroups',
              'fa-duotone fa-users-viewfinder',
              '/app/main/student/houseGroup'
            ),

            new AppMenuItem(
              'Student Registration',
              'Pages.StudentRegistrations',
              'fa-duotone fa-registered',
              '/app/main/student/studentRegistration'
            ),
            new AppMenuItem(
              'Student Attendance',
              'Pages.StudentAttendances',
              'fa-duotone fa-check',
              '/app/main/student/studentAttendance'
            ),
            new AppMenuItem(
              'Absent Application',
              'Pages.StudentAbsents',
              'fa-duotone fa-pen-to-square',
              '/app/main/student/absentApplication'
            ),
            new AppMenuItem(
              'Class Location',
              'Pages.StudentAbsents',
              'fa-duotone fa-pen-to-square',
              '/app/main/student/classLocation'
            ),

            new AppMenuItem(
              'ClassGeoLocation',
              'Pages.ClassGeoLocation',
              'fa-duotone fa-pen-to-square',
              '/app/main/student/classGeoLocation'
            ),
            new AppMenuItem(
              'GpsAttendances',
              'Pages.GpsAttendances',
              'fa-duotone fa-pen-to-square',
              '/app/main/student/gpsAttendances'
            ),
            //Navigation for Parent
            new AppMenuItem('Parents', 'Pages.Parent', 'fa-duotone fa-people-roof', '/app/main/student/Parents'),
            //Navigation for Teacher Review
            new AppMenuItem(
              'Teacher Reviews',
              'Pages.TeacherReviews',
              'fa-duotone fa-star-sharp-half-stroke',
              '/app/main/student/TeacherReview'
            ),
          ]
        ),

        new AppMenuItem(
          'Exam',
          'Pages.Exam',
          'fa-duotone fa-money-check-pen',
          '',
          [],
          [
            new AppMenuItem(
              'Exam Master',
              'Pages.Exams',
              'fa-duotone fa-memo-circle-info',
              '/app/main/exam/examMaster'
            ),
            new AppMenuItem(
              'Exam Marksetup',
              'Pages.ExamMarkSetups',
              'fa-duotone fa-pen-field',
              '/app/main/exam/examMarksetup'
            ),
            new AppMenuItem(
              'Exam Routine',
              'Pages.ExamRoutines',
              'fa-duotone fa-calendar-days',
              '/app/main/exam/examRoutine'
            ),
            new AppMenuItem(
              'Symbol Number',
              'Pages.AccountGroups',
              'fa-duotone fa-input-numeric',
              '/app/main/exam/symbolNumber'
            ),
            new AppMenuItem(
              'Exam Attendance',
              'Pages.ExamAttendances',
              'fa-duotone fa-calendar-plus',
              '/app/main/exam/examAttendance'
            ),
            new AppMenuItem(
              'Exam Result Publish',
              'Pages.ExamResultPublishs',
              'fa-duotone fa-calendar-check',
              '/app/main/exam/resultPublish'
            ),
            new AppMenuItem(
              'Consolidate Mark Setup',
              'Pages.ConsolidateExams',
              'fa-duotone fa-gear',
              '/app/main/exam/consoidateMarkSetup'
            ),
            new AppMenuItem(
              'Subject Wise Mark',
              'Pages.AccountGroups',
              'fa-duotone fa-book',
              '/app/main/exam/subjectWiseMark'
            ),
            new AppMenuItem(
              'Student Wise Mark',
              'Pages.AccountGroups',
              'fa-duotone fa-users',
              '/app/main/exam/studentWiseMark'
            ),
            new AppMenuItem('Theme Mark SetUp', '', 'fa-duotone fa-money-check-pen', '/app/main/exam/themeMarkSetup'),
            new AppMenuItem(
              'Theme Exam Mark Entry',
              'Pages.AccountGroups',
              'fa-duotone fa-money-check-pen',
              '/app/main/exam/themeExamMarkEntry'
            ),

            new AppMenuItem('Objective Exam', '', 'fa-duotone fa-list-check', '/app/main/exam/objectiveExam'),
          ]
        ),

        new AppMenuItem(
          'Fee',
          'Pages.Fee',
          'fa-duotone fa-dollar-sign',
          '',
          [],
          [
            new AppMenuItem('Fee Master', 'Pages.FeeHeads', 'fa-duotone fa-sack', '/app/main/fee/feemaster'),
            new AppMenuItem(
              'Installment',
              'Pages.FeeCollections',
              'fa-duotone fa-credit-card-front',
              '/app/main/fee/Installment'
            ),
            new AppMenuItem('Course Fee Rate', 'Pages.ClassFees', 'fa-duotone fa-percent', '/app/main/fee/classFeeRate'),
            new AppMenuItem(
              'Fee Mapping',
              'Pages.MonthlyFees',
              'fa-duotone fa-circle-nodes',
              '/app/main/fee/monthlyMapping'
            ),
            new AppMenuItem(
              'Student Fee Mapping',
              'Pages.StudentFeeMappings',
              'fa-duotone fa-chart-column',
              '/app/main/fee/studentFeeMapping'
            ),
            new AppMenuItem(
              'Opening Fees',
              'Pages.OpeningFees',
              'fa-duotone fa-circle-dollar-to-slot',
              '/app/main/fee/openingfees'
            ),
            new AppMenuItem('Hostel Fee', 'Pages.HostelFees', 'fa-duotone fa-money-bill', '/app/main/fee/hostelfees'),
            new AppMenuItem('Fare Fee', 'Pages.FareFees', 'fa-duotone fa-piggy-bank', '/app/main/fee/farefee'),
            new AppMenuItem('Other Fee', 'Pages.OtherFees', 'fa-duotone fa-wallet', '/app/main/fee/otherfee'),
            new AppMenuItem(
              'Fee Collection',
              'Pages.FeeCollections',
              'fa-duotone fa-envelope-open-dollar',
              '/app/main/fee/feeCollection'
            ),

            // new AppMenuItem(
            //     'Student Due',
            //     'Pages.FeeCollections',
            //     'fa-duotone fa-credit-card-front',
            //     '/app/main/fee/studentdue'
            // ),
          ]
        ),

        new AppMenuItem(
          'Transaction',
          '',
          'fa-duotone fa-money-bill-transfer',
          '',
          [],
          [
            new AppMenuItem(
              'ContraMasters',
              '',
              'fa-duotone fa-building-columns',
              '/app/main/transaction/contraMasters'
            ),
            new AppMenuItem(
              'PaymentMasters',
              'Pages.PaymentMasters',
              'fa-duotone fa-sack-dollar',
              '/app/main/transaction/paymentMasters'
            ),
            new AppMenuItem(
              'ReceiptMasters',
              'Pages.ReceiptMasters',
              'fa-duotone fa-receipt',
              '/app/main/transaction/receiptMaster'
            ),
            new AppMenuItem(
              'JournalMasters',
              'Pages.JournalMasters',
              'fa-duotone fa-book-open',
              '/app/main/transaction/journalMasters'
            ),
            new AppMenuItem(
              'BankReconciliation',
              'Pages.BankReconciliations',
              'fa-duotone fa-piggy-bank',
              '/app/main/transaction/bankReconciliation'
            ),
            new AppMenuItem('LoanMaster', 'Pages.LoanMaster', 'pi pi-list', '/app/main/transaction/loanMaster'),
            new AppMenuItem(
              'PdcPayable',
              'Pages.PdcPayables',
              'fa-duotone fa-hand-holding-dollar',
              '/app/main/transaction/PdcPayable'
            ),
            new AppMenuItem(
              'PdcReceivable',
              'Pages.PdcReceivables',
              'fa-duotone fa-hands-holding-dollar',
              '/app/main/transaction/PdcReceivable'
            ),
            new AppMenuItem(
              'PdcClearance',
              'Pages.PdcClearances',
              'fa-duotone fa-circle-check',
              '/app/main/transaction/PdcClearance'
            ),]),

        new AppMenuItem(
          'Library',
          'Pages.Library',
          'fa-duotone fa-books',
          '',
          [],
          [
            new AppMenuItem('Book Category', 'Pages.BookCategories', 'fa-duotone fa-book', '/app/main/library/bookCategory'),
            new AppMenuItem('Book Master', 'Pages.Books', 'fa-duotone fa-book', '/app/main/library/bookMaster'),
            new AppMenuItem(
              'Received Book',
              'Pages.BookReceiveds',
              'fa-duotone fa-book-bible',
              '/app/main/library/receivedBook'
            ),
            new AppMenuItem(
              'Issued Book',
              'Pages.BookIssueds',
              'fa-duotone fa-book-arrow-up',
              '/app/main/library/issuedBook'
            ),
            new AppMenuItem(
              'Book Return',
              'Pages.BookIssueReturns',
              'fa-duotone fa-book-arrow-right',
              '/app/main/library/bookReturn'
            ),
            new AppMenuItem(
              'Book Search',
              'Pages.BookSearch',
              'fa-duotone fa-book-atlas',
              '/app/main/library/bookSearch'
            ),
            new AppMenuItem(
              'Late Fine',
              'Pages.FineSchemas',
              'fa-duotone fa-money-check-alt',
              '/app/main/library/lateFine'
            ),
            new AppMenuItem(
              'Members',
              'Pages.LibraryMembers',
              'fa-duotone fa-users',
              '/app/main/library/libraryMember'
            ),
            new AppMenuItem(
              'Book Hold',
              'Pages.LibraryMembers',
              'fa-duotone fa-book-open-reader',
              '/app/main/library/bookHold'
            ),
            new AppMenuItem(
              'Book Donor',
              'Pages.LibraryMembers',
              'fa-duotone fa-hand-holding-box',
              '/app/main/library/bookDonor'
            ),
          ]
        ),

        new AppMenuItem(
          'LMS',
          'Pages.Lms',
          'fa-duotone fa-person-chalkboard',
          '',
          [],
          [
            new AppMenuItem(
              'Digital Content',
              '',
              'fa-duotone fa-image',
              '/app/main/lms/digital-content'
            ),
            new AppMenuItem(
              'Assignment Categories',
              'Pages.AssignmentCategories',
              'fa-duotone fa-boxes-stacked',
              '/app/main/lms/assignmentCategory'
            ),
            new AppMenuItem('Assignment', 'Pages.Assignments', 'fa-duotone fa-pen-field', '/app/main/lms/assignment'),
            new AppMenuItem(
              'BackgroundImage',
              'Pages.Assignments',
              'fa-duotone fa-image',
              '/app/main/lms/BackgroundImage'
            ),
            new AppMenuItem(
              'ELearning',
              'Pages.Assignments',
              'fa-duotone fa-image',
              '/app/main/lms/midas-lms'
            ),


            new AppMenuItem(
              'Course Categories',
              'Pages.CourseCategories',
              'fa-duotone fa-layer-group',
              '/app/main/lms/courseCategory'
            ),
            new AppMenuItem('Course', 'Pages.LmsCourses', 'fa-duotone fa-chalkboard-user', '/app/main/lms/lmscourse'),
            new AppMenuItem('Course Unit', 'Pages.CourseUnits', 'fa-duotone fa-book', '/app/main/lms/courseUnit'),
            new AppMenuItem('Homeworks', 'Pages.HomeWorks', 'fa-duotone fa-money-check-pen', '/app/main/lms/homework'),

            new AppMenuItem(
              'Online Class',
              'Pages.OnlineClasses',
              'fa-duotone fa-laptop',
              '/app/main/lms/online-class'
            ),
            new AppMenuItem('Lecture Note', 'Pages.LectureNotes', 'fa-duotone fa-note', '/app/main/lms/Lecture-Note'),

            new AppMenuItem('E Book', 'Pages.LectureNotes', 'fa-duotone fa-book-atlas', '/app/main/lms/e-book'),
            new AppMenuItem('Quiz', 'Pages.AssignmentCategories', 'fa-duotone fa-boxes-stacked', '/app/main/lms/quiz'),
            new AppMenuItem(
              'Elibrary',
              'Pages.ELibrary',
              'fa-duotone fa-laptop',
              '',
              [],

              [new AppMenuItem('Midas LMS', '', 'fa-duotone fa-books', 'https://www.midaselibrary.com/login/autologin?username=9848427993&password=dHVraTk4NDg0Mjc5OTNyc3Nr&logintype=teacher&from=vendor&vendorid=TUKI&vendorpass=f2732397c8dfe63afa30f4299ac9106b'),

              new AppMenuItem('Chred Portal', '', 'fa-duotone fa-books', 'https://learning.cehrd.edu.np/home'),

              new AppMenuItem('Book', 'Pages.Books', 'fa-duotone fa-book', '/app/main/elibrary/book'),
              new AppMenuItem(
                'Class Note',
                'Pages.AccountGroups',
                'fa-duotone fa-book-open',
                '/app/main/elibrary/classnote'
              ),
              ]
            ),
          ]
        ),

        new AppMenuItem(
          'Stock',
          '',
          'fa-duotone fa-cart-flatbed-boxes',
          '',
          [],
          [
            new AppMenuItem(
              'Inventory',
              'Pages.Inventory',
              'fa-duotone fa-cart-flatbed-boxes',
              '',
              [],
              [
                new AppMenuItem('Units', 'Pages.Units', 'fa-duotone fa-weight-scale', '/app/main/inventory/units'),
                new AppMenuItem('Tax', 'Pages.Taxes', 'fa-duotone fa-weight-scale', '/app/main/inventory/taxes'),
                new AppMenuItem('Godowns', 'Pages.Godowns', 'fa-duotone fa-store', '/app/main/inventory/godowns'),
                new AppMenuItem('Product Groups', 'Pages.ProductGroups', 'fa-duotone fa-ball-pile', '/app/main/inventory/productGroups'),
                new AppMenuItem('Products', 'Pages.Products', 'fa-duotone fa-boxes-stacked', '/app/main/inventory/products'),
                new AppMenuItem('Physical Stock', 'Pages.ItemIssueMasters', 'fa-duotone fa-boxes-stacked', '/app/main/inventory/physical-stock'),
                new AppMenuItem('Item Demands', 'Pages.ItemDemands', 'fa-duotone fa-boxes-stacked', '/app/main/inventory/items-demand'),
                new AppMenuItem('Item Issue', 'Pages.ItemIssue', 'fa-duotone fa-boxes-stacked', '/app/main/inventory/items-issue'),
              ]),

            new AppMenuItem(
              'Purchase',
              'Pages.Purchase',
              'fa-duotone fa-cart-shopping-fast',
              '',
              [],
              [
                new AppMenuItem('Purchase Order', 'Pages.PurchaseOrderMasters', 'fa-duotone fa-cart-plus', '/app/main/purchase/purchaseOrderMasters'),
                new AppMenuItem('Purchase Invoice', 'Pages.PurchaseMasters', 'fa-duotone fa-file-invoice-dollar', '/app/main/purchase/purchaseMasters'),
                new AppMenuItem('Purchase Returns', 'Pages.PurchaseReturns', 'fa-duotone fa-rotate-left', '/app/main/purchase/purchaseReturns'),
              ]
            ),
            new AppMenuItem(
              'Sales',
              '',
              'fa-duotone fa-cart-circle-check',
              '',
              [],
              [
                // new AppMenuItem('SalesQuotation', '', 'fa-duotone fa-dolly', '/app/main/sales/salesQuotationMasters'),
                // new AppMenuItem('SalesOrder', 'Pages.LectureNotes', 'fa-duotone fa-bags-shopping', '/app/main/sales/salesOrderMaster'),
                // new AppMenuItem('DeliveryNote', 'Pages.LectureNotes', 'fa-duotone fa-notebook', '/app/main/sales/deliveryNoteMaster'),
                // new AppMenuItem('RejectionIn', 'Pages.LectureNotes', 'fa-duotone fa-circle-xmark', '/app/main/sales/rejectionIn'),
                new AppMenuItem('Sales Invoice', 'Pages.LectureNotes', 'fa-duotone fa-file-invoice-dollar', '/app/main/sales/salesInvoiceMasters'),
                new AppMenuItem('Sales Return', 'Pages.LectureNotes', 'fa-duotone fa-cart-circle-xmark', '/app/main/sales/salesReturnMasters'),
                // new AppMenuItem('SalesPos', 'Pages.LectureNotes', 'fa-duotone fa-cart-plus', '/app/main/sales/sales-pos'),

              ]),

          ]),


        new AppMenuItem(
          'Accounting',
          'Pages.Accounting',
          'fa-duotone fa-money-check-dollar-pen',
          '',
          [],
          [
            new AppMenuItem(
              'AccountGroups',
              'Pages.AccountGroups',
              'fa-duotone fa-users',
              '/app/main/accounting/accountGroups'
            ),
            new AppMenuItem(
              'AccountLedgers',
              'Pages.AccountLedgers',
              'fa-duotone fa-receipt',
              '/app/main/accounting/accountLedgers'
            ),
            new AppMenuItem(
              'FinancialYears',
              'Pages.FinancialYears',
              'fa-duotone fa-calendar-days',
              '/app/main/accounting/financialYears'
            ),
            new AppMenuItem(
              'AcademicYears',
              'Pages.AcademicYears',
              'fa-duotone fa-calendar-days',
              '/app/main/accounting/academiaYears'
            ),
            new AppMenuItem(
              'VoucherType',
              'Pages.VoucherTypes',
              'fa-duotone fa-receipt',
              '/app/main/generalSetting/voucherTypes'
            ),
            new AppMenuItem(
              'MergeLedger',
              'Pages.VoucherTypes',
              'fa-duotone fa-file-circle-plus',
              '/app/main/accounting/mergeLedger'
            ),
            new AppMenuItem(
              'DocumentNumering',
              'Pages.VoucherTypes',
              'fa-duotone fa-input-numeric',
              '/app/main/generalSetting/documentNumering'
            ),
          ]
        ),

        new AppMenuItem(
          'Payroll',
          '',
          'fa-duotone fa-hand-holding-dollar',
          '',
          [],
          [
            new AppMenuItem(
              'Departments',
              'Pages.Departments',
              'fa-duotone fa-building-columns',
              '/app/main/controlPanel/departments'
            ),
            new AppMenuItem(
              'Designations',
              'Pages.Designations',
              'fa-duotone fa-user-tie',
              '/app/main/controlPanel/designations'
            ),
            new AppMenuItem('PayHeads', 'Pages.PayHeads', 'fa-duotone fa-user-check', '/app/main/payroll/payHeads'),
            new AppMenuItem('WorkUnit', 'Pages.WorkUnits', 'fa-duotone fa-screen-users', '/app/main/payroll/workUnit'),
            new AppMenuItem('Teacher Log', 'Pages.PayHeads', 'fa-duotone fa-memo', '/app/main/payroll/teacher-log'),
            new AppMenuItem(
              'EmployeeTaxSetting',
              'Pages.EmployeeTaxSettings',
              'fa-duotone fa-credit-card',
              '/app/main/payroll/employeeTaxSetting'
            ),
            new AppMenuItem('Employees', 'Pages.Employees', 'fa-duotone fa-users', '/app/main/controlPanel/employees'),
            new AppMenuItem(
              'EmployeesCalender',
              'Pages.Employees',
              'fa-duotone fa-calendar-days',
              '/app/main/payroll/employeeCalendar'
            ),
            new AppMenuItem(
              'EmployeeOffice',
              'Pages.AttendanceMasters',
              'fa-duotone fa-buildings',
              '/app/main/payroll/EmployeeOffice'
            ),
            new AppMenuItem(
              'StaffAttendance',
              'Pages.AttendanceMasters',
              'fa-duotone fa-rainbow',
              '/app/main/payroll/staffAttendance'
            ),
            new AppMenuItem(
              'BonusDeduction',
              'Pages.EmployeeBonusDeductions',
              'fa-duotone fa-circle-minus',
              '/app/main/payroll/BonusDeduction'
            ),
            new AppMenuItem(
              'AdvancePayment',
              'Pages.EmployeeLoanAndAdvances',
              'fa-duotone fa-dollar-sign',
              '/app/main/payroll/advancePayment'
            ),
            new AppMenuItem(
              'LopDeduction',
              'Pages.EmployeeLopDeductionMasters',
              'fa-duotone fa-user-minus',
              '/app/main/payroll/lopDeduction'
            ),
            new AppMenuItem(
              'EmployeeOvertime',
              'Pages.EmployeeOverTimes',
              'fa-duotone fa-user-clock',
              '/app/main/payroll/EmployeeOvertime'
            ),
            new AppMenuItem(
              'MonthlySalary',
              'Pages.EmployeeSalaryVoucherMasters',
              'fa-duotone fa-money-bills',
              '/app/main/payroll/monthlySalary'
            ),
            new AppMenuItem('PaySlips', 'Pages.PayHeads', 'fa-duotone fa-memo', '/app/main/payroll/paySlip'),
            new AppMenuItem(
              'OnlineMeeting',
              'Pages.OnlineMeetings',
              'fa-duotone fa-building-columns',
              '/app/main/payroll/onlinemeeting'
            ),
          ]
        ),
        new AppMenuItem(
          'Report',
          'Pages.Reporting',
          'fa-duotone fa-chart-simple',
          '',
          [],

          [
            new AppMenuItem(
              'FinancialStatement',
              'Pages.FinancialStatement',
              'fa-duotone fa-chart-mixed',
              '',
              [],
              [
                new AppMenuItem(
                  'TrialBalance',
                  'Pages.TrialBalanceReport',
                  'fa-duotone fa-receipt',
                  '/app/reports/trial-balance'
                ),
                new AppMenuItem(
                  'ProfitandLoss',
                  'Pages.ProfitAndLossReport',
                  'fa-duotone fa-arrow-down-arrow-up',
                  '/app/reports/profit-loss'
                ),
                new AppMenuItem(
                  'BalanceSheet',
                  'Pages.PagesBalanceSheetReport',
                  'fa-duotone fa-memo-pad',
                  '/app/reports/balance-sheet'
                ),
                new AppMenuItem(
                  'CashFlow',
                  'Pages.PagesCashFlowReport',
                  'fa-duotone fa-money-bill-transfer',
                  '/app/reports/cash-flow'
                ),
              ]
            ),
            new AppMenuItem(
              'Student Report',
              'Pages.StudentReporting',
              'fa-duotone fa-chart-user',
              '',
              [],
              [
                new AppMenuItem(
                  'Identity Card',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-id-card',
                  '/app/main/student-report/identity-card'
                ),
                new AppMenuItem(
                  'Student Leave Note',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-notes',
                  '/app/main/student-report/student-leave-note'
                ),
                new AppMenuItem(
                  'Student Details',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-circle-info',
                  '/app/main/student-report/student-details'
                ),
                new AppMenuItem(
                  'Student Attendance Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-calendar-plus',
                  '/app/main/student-report/student-attendance-report'
                ),
                new AppMenuItem(
                  'Absent Application Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-calendar-minus',
                  '/app/main/student-report/absent-application-report'
                ),
                new AppMenuItem(
                  'Fee DayBook Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-notes',
                  '/app/main/student-report/fee-daybook-report'
                ),
                new AppMenuItem(
                  'Student Wise DayBook Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-notes',
                  '/app/main/student-report/student-wise-daybook'
                ),
              ]
            ),

            new AppMenuItem(
              'Fee Report',
              '',
              'fa-duotone fa-chart-column',
              '',
              [],
              [
                new AppMenuItem(
                  'Online Payment Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-credit-card',
                  '/app/reports/fee-report/online-payment-report'
                ),
                new AppMenuItem(
                  'Monthly Due Analysis',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-chart-simple',
                  '/app/reports/fee-report/monthly-due-analysis'
                ),
                new AppMenuItem(
                  'Balance Summary',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-money-check-dollar-pen',
                  '/app/reports/fee-report/balance-summary'
                ),
                new AppMenuItem(
                  'Discount Summary',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-money-check-pen',
                  '/app/reports/fee-report/discount-summary'
                ),
                new AppMenuItem(
                  'Opening Fee Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-dollar-sign',
                  '/app/reports/fee-report/other-fee'
                ),
                new AppMenuItem(
                  'Student Balance',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-dollar-sign',
                  '/app/reports/fee-report/student-balance'
                ),
                new AppMenuItem(
                  'Student Fee Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-dollar-sign',
                  '/app/reports/fee-report/app-student-fee-report'
                ),
                new AppMenuItem(
                  'Student Balance Due Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-square-poll-vertical',
                  '/app/exam-report/student-banance-due-report'
                ),

                new AppMenuItem(
                  'Due Fee Summary Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-square-poll-vertical',
                  '/app/reports/fee-report/due-fee-summary'
                ),

                new AppMenuItem(
                  'Fee Collection Summary Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-square-poll-vertical',
                  '/app/reports/fee-report/fee-collection-summary'
                ),
                new AppMenuItem(
                  'Course Pending Collection ',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-square-poll-vertical',
                  '/app/reports/fee-report/class-wise-feecollection'
                ),
                new AppMenuItem(
                  'Installment Wise Fee collection Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-square-poll-vertical',
                  '/app/reports/fee-report/installment-wise-feecollection'
                ),
              ]
            ),
            new AppMenuItem(
              'Exam Report',
              '',
              'fa-duotone fa-chart-mixed',
              '',
              [],
              [
                new AppMenuItem(
                  'Admit Card',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-id-card-clip',
                  '/app/exam-report/admit-card'
                ),
                new AppMenuItem(
                  'Grade Ledger',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo-pad',
                  '/app/exam-report/grade-ledger-report'
                ),
                new AppMenuItem(
                  'Marks Ledger',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo-pad',
                  '/app/exam-report/marks-ledger-report'
                ),
                new AppMenuItem(
                  'Elective subject Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-chart-gantt',
                  '/app/exam-report/elective-subject-report'
                ),
                new AppMenuItem(
                  'Marksheet',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo-circle-check',
                  '/app/exam-report/marksheet-report'
                ),
                new AppMenuItem(
                  'Pass Fail Analysis',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-list-check',
                  '/app/exam-report/pass-fail-analysis'
                ),
                new AppMenuItem(
                  'Attendance Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-square-poll-vertical',
                  '/app/exam-report/attendance-report'
                ),

                new AppMenuItem(
                  'Character Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-list-ol',
                  '/app/exam-report/character-report'
                ),
                new AppMenuItem(
                  'Theme Marksheet Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-list-ol',
                  '/app/exam-report/theme-exam-mark-report'
                ),
                new AppMenuItem(
                  'Subject Wise ThemeMarkEntry Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-list-ol',
                  '/app/exam-report/subject-wise-theme-mark-report'
                ),
                new AppMenuItem(
                  'Topper List',
                  'Pages.CrmTask',
                  'fa-duotone fa-list-ol',
                  '/app/exam-report/topper-list'
                ),
              ]
            ),

            new AppMenuItem(
              'Transport Report',
              '',
              'fa-duotone fa-file-chart-column',
              '',
              [],
              [
                new AppMenuItem(
                  'Bus Stop Wise Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo',
                  '/app/reports/transport-report/bus-stop-wise'
                ),
                new AppMenuItem(
                  'Bus Wise Summary Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo-pad',
                  '/app/reports/transport-report/bus-wise-summary'
                ),
                new AppMenuItem(
                  'Class Wise Summary Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo-circle-check',
                  '/app/reports/transport-report/class-wise-summary'
                ),
                new AppMenuItem(
                  'Student Summary Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo-pad',
                  '/app/reports/transport-report/student-summary-report'
                ),
                new AppMenuItem(
                  'Bus Attendance Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-chart-column',
                  '/app/reports/transport-report/bus-appendance-report'
                ),
              ]
            ),

            new AppMenuItem(
              'Accounting Report',
              'Pages.AccountingReport',
              'fa-duotone fa-utensils',
              '',

              [],
              [
                new AppMenuItem(
                  'AccountGroup',
                  'Pages.AccountGroupReport',
                  'fa-duotone fa-user-group',
                  '/app/reports/account-group'
                ),
                new AppMenuItem(
                  'AccountLedger',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo',
                  '/app/reports/account-ledger-report'
                ),

                new AppMenuItem(
                  'CashBankBook',
                  'Pages.CashBankBookReport',
                  'fa-duotone fa-book',
                  '/app/reports/cash-bank'
                ),
                new AppMenuItem('Cheque', 'Pages.ChequeReport', 'fa-duotone fa-money-check-pen', '/app/reports/cheque'),
                new AppMenuItem(
                  'DayBook',
                  'Pages.DayBookReport',
                  'fa-duotone fa-book-open-cover',
                  '/app/reports/day-book'
                ),
                // new AppMenuItem(
                //     'PaymentVoucher',
                //     'Pages.PaymentVoucherReport',
                //     'fa-duotone fa-notes-medical',
                //     '/app/reports/payment-voucher'
                // ),
                new AppMenuItem(
                  'ReceiptVoucher',
                  'Pages.ReceiptVoucherReport',
                  'fa-duotone fa-receipt',
                  '/app/reports/receipt-voucher'
                ),
                new AppMenuItem('ContraMasters', 'Pages.ContraMasterReport', 'fa-duotone fa-bank', '/app/reports/contra-master'),
                new AppMenuItem(
                  'JournalMasters',
                  'Pages.JournalMasterReport',
                  'fa-duotone fa-newspaper',
                  '/app/reports/journalMaster'
                ),
                new AppMenuItem(
                  'PdcReceivable',
                  'Pages.PdcReceivableReport',
                  'fa-duotone fa-circle-down',
                  '/app/reports/PdcReceivable'
                ),
                new AppMenuItem(
                  'PdcClearance',
                  'Pages.PdcClearanceReport',
                  'fa-duotone fa-circle-check',
                  '/app/reports/PdcClearance'
                ),
                new AppMenuItem(
                  'PdcPayable',
                  'Pages.PdcPayableReport',
                  'fa-duotone fa-circle-up',
                  '/app/reports/pdc-payable'
                ),

                new AppMenuItem(
                  'Bus Wise Details',
                  'Pages.InterestReport',
                  'fa-duotone fa-circle-info',
                  '/app/reports/bus-wise-details'
                ),
              ]
            ),
            new AppMenuItem(
              'TaxReport',
              'Pages.TaxReporting',
              'fa-duotone fa-memo-pad',
              '',
              [],
              [
                  new AppMenuItem(
                      'VatSummaryReport',
                      'Pages.VatSummaryReport',
                      'fa-duotone fa-percent',
                      '/app/reports/tax-report/vatSummaryNewReport'
                  ),
                  new AppMenuItem(
                      'Purchase Tax Report',
                      'Pages.TaxPurchaseRegisterReport',
                      'fa-duotone fa-chart-mixed',
                      '/app/reports/tax-report/purchaseTaxReport'
                  ),
                  new AppMenuItem(
                      'Sales Tax Report',
                      'Pages.TaxSalesRegisterReport',
                      'fa-duotone fa-chart-line-up',
                      '/app/reports/tax-report/salesTaxReport'
                  ),
                  new AppMenuItem(
                      'Sales Above Lakhs',
                      'Pages.SalesReturnReport',
                      'fa-duotone fa-chart-line',
                      '/app/reports/tax-report/salesAboveLakhsReport'
                  ),
                  new AppMenuItem(
                      'Purchase Above Lakhs',
                      'Pages.SalesReturnReport',
                      'fa-duotone fa-cart-shopping',
                      '/app/reports/tax-report/purchaseAboveLakhsReport'
                  ),

              ]
          ),
            new AppMenuItem(
              'Inventory',
              'Pages.Lunchs',
              'fa-duotone fa-list',
              '',

              [],
              [
                new AppMenuItem(
                  'Physical Stock',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo',
                  '/app/reports/inventory/physical-stock'
                ),

                new AppMenuItem(
                  'Material Sales Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-memo',
                  '/app/reports/material-sales-report'
                ),
                new AppMenuItem(
                  'Stock Report',
                  'Pages.AccountLedgerReport',
                  'fa-duotone fa-layer-group',
                  '/app/reports/stockUnfinished'
                ),
              ]
            ),
            new AppMenuItem(
              'Fee DayBook',
              'Pages.AssignmentCategories',
              'fa-duotone fa-boxes-stacked',
              '/app/reports/fee-report/fee-dayBook'
            ),
          ]
        ),

        new AppMenuItem(
          'Setting',
          '',
          'fa-duotone fa-gear',
          '',
          [],
          [
            new AppMenuItem(
              'Student Migration',
              'Pages.Branchs',
              'fa-duotone fa-buildings',
              '/app/main/student/studentMigration'
            ),
            new AppMenuItem('Company', 'Pages.Branchs', 'fa-duotone fa-buildings', '/app/main/controlPanel/branchs'),
            new AppMenuItem('Message', 'Pages.Branchs', 'fa-duotone fa-buildings', '/app/main/controlPanel/message'),
            new AppMenuItem(
              'SmsTemplate',
              'Pages.SmsTemplate',
              'fa-duotone fa-receipt',
              '/app/main/generalSetting/smsTemplate'
            ),
            new AppMenuItem('Address', 'Pages.Branchs', 'fa-duotone fa-location-dot', '/app/main/controlPanel/address'),
            new AppMenuItem(
              'OrganizationUnits',
              'Pages.Administration.OrganizationUnits',
              'fa-duotone fa-house-blank',
              '/app/admin/organization-units'
            ),
            new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-duotone fa-person', '/app/admin/roles'),
            new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-duotone fa-users', '/app/admin/users'),
            new AppMenuItem(
              'Languages',
              'Pages.Administration.Languages',
              'fa-duotone fa-language',
              '/app/admin/languages',
              ['/app/admin/languages/{name}/texts']
            ),
            new AppMenuItem(
              'AuditLogs',
              'Pages.Administration.AuditLogs',
              'fa-duotone fa-list-check',
              '/app/admin/auditLogs'
            ),
            new AppMenuItem(
              'Maintenance',
              'Pages.Administration.Host.Maintenance',
              'fa-duotone fa-sliders',
              '/app/admin/maintenance'
            ),
            new AppMenuItem(
              'Subscription',
              'Pages.Administration.Tenant.SubscriptionManagement',
              'fa-duotone fa-hand-pointer',
              '/app/admin/subscription-management'
            ),
            new AppMenuItem(
              'VisualSettings',
              'Pages.Administration.UiCustomization',
              'fa-duotone fa-eye',
              '/app/admin/ui-customization'
            ),
            new AppMenuItem(
              'WebhookSubscriptions',
              'Pages.Administration.WebhookSubscription',
              'fa-duotone fa-bell',
              '/app/admin/webhook-subscriptions'
            ),
            new AppMenuItem(
              'DynamicProperties',
              'Pages.Administration.DynamicProperties',
              'fa-duotone fa-star',
              '/app/admin/dynamic-property'
            ),
            new AppMenuItem(
              'Settings',
              'Pages.Administration.Host.Settings',
              'fa-duotone fa-gear',
              '/app/admin/hostSettings'
            ),
            new AppMenuItem(
              'Settings',
              'Pages.Administration.Tenant.Settings',
              'fa-duotone fa-gear',
              '/app/admin/tenantSettings'
            ),
          ]
        ),
      ]);
    }
  }

  checkChildMenuItemPermission(menuItem): boolean {
    for (let i = 0; i < menuItem.items.length; i++) {
      let subMenuItem = menuItem.items[i];

      if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
        if (subMenuItem.route) {
          return true;
        }
      } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
        return true;
      }

      if (subMenuItem.items && subMenuItem.items.length) {
        let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
        if (isAnyChildItemActive) {
          return true;
        }
      }
    }
    return false;
  }

  showMenuItem(menuItem: AppMenuItem): boolean {
    if (
      menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
      this._appSessionService.tenant &&
      !this._appSessionService.tenant.edition
    ) {
      return false;
    }

    let hideMenuItem = false;

    if (menuItem.requiresAuthentication && !this._appSessionService.user) {
      hideMenuItem = true;
    }

    if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
      hideMenuItem = true;
    }

    if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
      if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
        hideMenuItem = true;
      }
    }

    if (!hideMenuItem && menuItem.items && menuItem.items.length) {
      return this.checkChildMenuItemPermission(menuItem);
    }

    return !hideMenuItem;
  }

  /**
   * Returns all menu items recursively
   */
  getAllMenuItems(): AppMenuItem[] {
    let menu = this.getMenu();
    let allMenuItems: AppMenuItem[] = [];
    menu.items.forEach((menuItem) => {
      allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
    });

    return allMenuItems;
  }

  private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
    if (!menuItem.items) {
      return [menuItem];
    }

    let menuItems = [menuItem];
    menuItem.items.forEach((subMenu) => {
      menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
    });

    return menuItems;
  }
}
