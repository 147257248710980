<div
        #mySettingsModal="bs-modal"
        (onShown)="onShown()"
        [config]="{ backdrop: 'static' }"
        appBsModal
        aria-hidden="true"
        aria-labelledby="myLargeModalLabel"
        class="modal fade"
        role="dialog"
        tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #mySettingsModalForm="ngForm" (ngSubmit)="save()" *ngIf="active">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'MySettings' | localize }}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="btn-close" type="button"></button>
                </div>
                <div *ngIf="user" class="modal-body">
                    <tabset>
                        <tab heading="{{ 'Profile' | localize }}">
                            <div class="mb-5">
                                <label for="Name">{{ 'FirstName' | localize }} *</label>
                                <input
                                        #nameInput="ngModel"
                                        [(ngModel)]="user.name"
                                        class="form-control"
                                        id="Name"
                                        maxlength="64"
                                        name="Name"
                                        required
                                        type="text"
                                />
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>

                            <div class="mb-5">
                                <label for="Surname">{{ 'Surname' | localize }} *</label>
                                <input
                                        #surnameInput="ngModel"
                                        [(ngModel)]="user.surname"
                                        class="form-control"
                                        id="Surname"
                                        maxlength="64"
                                        name="Surname"
                                        required
                                        type="text"
                                />
                                <validation-messages [formCtrl]="surnameInput"></validation-messages>
                            </div>

                            <div class="mb-5">
                                <label for="EmailAddress">{{ 'EmailAddress' | localize }} *</label>
                                <input
                                        #emailAddressInput="ngModel"
                                        [(ngModel)]="user.emailAddress"
                                        class="form-control"
                                        email
                                        id="EmailAddress"
                                        maxlength="256"
                                        name="EmailAddress"
                                        required
                                        type="email"
                                />
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>
                            <div *ngIf="smsEnabled">
                                <div class="mb-5">
                                    <label>{{ 'PhoneNumber' | localize }}</label>
                                    <div class="input-group">
                                        <input
                                                [(ngModel)]="user.phoneNumber"
                                                class="form-control"
                                                maxlength="32"
                                                name="PhoneNumber"
                                                type="text"
                                        />

                                        <span class="input-group-append" id="SpanSmsVerificationUnverified">
                                            <button
                                                    (click)="smsVerify()"
                                                    [disabled]="
                                                    isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber
                                                "
                                                    class="btn btn-primary"
                                                    id="btnSmsVerification"
                                                    tooltip="{{ 'YourPhoneNumberIsNotVerified' | localize }}"
                                                    type="button"
                                            >
                                                <i class="fa-duotone fa-exclamation-triangle"></i>
                                                {{ 'Verify' | localize }}
                                            </button>
                                        </span>

                                        <div
                                                *ngIf="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber"
                                                class="input-group-append"
                                                id="SpanSmsVerificationVerified"
                                        >
                                            <span
                                                    class="input-group-text"
                                                    tooltip="{{ 'YourPhoneNumberIsVerified' | localize }}"
                                            >
                                                <i
                                                        [attr.aria-label]="l('Verified')"
                                                        class="la la-check label-success"
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-5">
                                <label for="UserName">{{ 'UserName' | localize }} *</label>
                                <input
                                        #userNameInput="ngModel"
                                        [(ngModel)]="user.userName"
                                        [disabled]="!canChangeUserName"
                                        class="form-control"
                                        id="UserName"
                                        maxlength="256"
                                        name="UserName"
                                        required
                                        type="text"
                                />
                                <span *ngIf="!canChangeUserName" class="form-text text-muted">
                                    {{ 'CanNotChangeAdminUserName' | localize }}
                                </span>
                                <validation-messages [formCtrl]="userNameInput"></validation-messages>
                            </div>

                            <div *ngIf="showTimezoneSelection" class="mb-5">
                                <label class="form-label" for="Timezone">{{ 'Timezone' | localize }}</label>
                                <timezone-combo
                                        [(ngModel)]="user.timezone"
                                        defaultTimezoneScope="{{ defaultTimezoneScope }}"
                                        name="Timezone"
                                ></timezone-combo>
                            </div>

                            <div *ngIf="!isMultiTenancyEnabled || isTwoFactorLoginEnabledForApplication">
                                <div class="mb-5">
                                    <div *ngIf="isGoogleAuthenticatorEnabled; else googleAuthenticatorEnabled">
                                        <div class="mb-3 fw-semibold">
                                            <h4
                                                    class="fw-bold fs-3 d-flex align-items-center"
                                                    style="color: #2b9348 !important"
                                            >
                                                <i class="fa-solid fa-lock me-2"></i>
                                                {{ 'AuthenticatorAppEnabled' | localize }}
                                            </h4>
                                            <span class="form-text text-muted">
                                                {{ 'AuthenticatorAppEnabledDescription' | localize }}
                                            </span>
                                        </div>
                                        <button
                                                (click)="viewRecoveryCodesModal.show()"
                                                class="btn btn-primary btn-sm"
                                                id="btnViewRecoveryCodes"
                                                type="button"
                                        >
                                            {{ 'ViewRecoveryCodes' | localize }}
                                        </button>
                                        <button
                                                (click)="verifyCodeModal.show()"
                                                class="btn btn-danger btn-sm"
                                                id="btnRemoveAuthenticator"
                                                type="button"
                                        >
                                            {{ 'DisableAuthenticatorApp' | localize }}
                                        </button>
                                    </div>

                                    <ng-template #googleAuthenticatorEnabled>
                                        <div
                                                class="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6"
                                        >
                                            <!--begin::Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen048.svg-->
                                            <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                <svg
                                                        fill="none"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                            d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                            fill="currentColor"
                                                            opacity="0.3"
                                                    ></path>
                                                    <path
                                                            d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                                            fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--end::Icon-->
                                            <!--begin::Wrapper-->
                                            <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                <!--begin::Content-->
                                                <div class="mb-3 mb-md-0 fw-semibold">
                                                    <h4 class="text-gray-900 fw-bold">
                                                        {{ 'EnableAuthenticatorApp' | localize }}
                                                    </h4>
                                                    <div class="fs-6 text-gray-700 pe-7">
                                                        {{ 'EnableAuthenticatorAppDescription' | localize }}
                                                    </div>
                                                </div>
                                                <!--end::Content-->
                                                <!--begin::Action-->
                                                <button
                                                        (click)="enableTwoFactorAuthentication()"
                                                        class="btn btn-primary px-6 align-self-center text-nowrap"
                                                        id="enableTwoFactorAuthenticationButton"
                                                        type="button"
                                                >
                                                    {{ 'Enable' | localize }}
                                                </button>
                                                <!--end::Action-->
                                            </div>
                                            <!--end::Wrapper-->
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button (click)="close()" [disabled]="saving" class="btn btn-light-primary fw-bold" type="button">
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                            [disabled]="!mySettingsModalForm.form.valid || saving"
                            class="btn btn-primary fw-bold"
                            type="submit"
                    >
                        <i class="fa-duotone fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal
        #smsVerificationModal
        (modalSave)="changePhoneNumberToVerified()"
        *ngIf="user"
        [newPhoneNumber]="user.phoneNumber"
></smsVerificationModal>

<enableTwoFactorAuthenticationModal #enableTwoFactor (modalSave)="close()"></enableTwoFactorAuthenticationModal>

<viewRecoveryCodesModal #viewRecoveryCodesModal></viewRecoveryCodesModal>
<verifyCodeModal
    #verifyCodeModal
    (modalSave)="disableTwoFactorAuthentication(verifyCodeModal.verifyCodeInput)"
></verifyCodeModal>
