import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
  providers: [
    ApiServiceProxies.AcademicYearsServiceProxy,
    ApiServiceProxies.SmsTemplateServiceProxy ,
    ApiServiceProxies.BatchServiceProxy,
    ApiServiceProxies.BookCategoriesServiceProxy,
    ApiServiceProxies.BookCategoriesServiceProxy,
    ApiServiceProxies.TaxesServiceProxy,
    ApiServiceProxies.PurchaseMastersServiceProxy,
    ApiServiceProxies.PurchaseReturnsServiceProxy,
    ApiServiceProxies.PurchaseOrderServiceProxy,
    ApiServiceProxies.UnitsServiceProxy,
    ApiServiceProxies.GodownsServiceProxy,
    ApiServiceProxies.SalesReturnMastersServiceProxy,
    ApiServiceProxies.ProductGroupsServiceProxy,
    ApiServiceProxies.ProductServiceProxy,
    ApiServiceProxies.EmployeePunchsServiceProxy,
    ApiServiceProxies.EmployeeShiftsServiceProxy,
    ApiServiceProxies.GpsAttendancesServiceProxy,
    ApiServiceProxies.ClassGeoLocationServiceProxy,
    ApiServiceProxies.BlogsServiceProxy,
    ApiServiceProxies.LectureNotesServiceProxy,
    ApiServiceProxies.LmsCoursesServiceProxy,
    ApiServiceProxies.ParentExamServiceProxy,
    ApiServiceProxies.ParentAcademicServiceProxy,
    ApiServiceProxies.QuizServiceProxy,
    ApiServiceProxies.ObjectExamServiceProxy,
    ApiServiceProxies.PdcCleranceReportServiceProxy,
    ApiServiceProxies.PdcPayableReportServiceProxy,
    ApiServiceProxies.WorkUnitsServiceProxy,
    ApiServiceProxies.OnlineMeetingServiceProxy,
    ApiServiceProxies.GalleryCategoriesServiceProxy,
    ApiServiceProxies.LunchFeesServiceProxy,
    ApiServiceProxies.TestimonialsServiceProxy,
    ApiServiceProxies.GalleryServiceProxy,
    ApiServiceProxies.CareersServiceProxy,
    ApiServiceProxies.BannerCategoriesServiceProxy,
    ApiServiceProxies.BannersServiceProxy,
    ApiServiceProxies.FeeCollectionsServiceProxy,
    ApiServiceProxies.InstallmentServiceProxy,
    ApiServiceProxies.CourseFeesServiceProxy,
    ApiServiceProxies.StudentFeeMappingsServiceProxy,
    ApiServiceProxies.BlogCategoriesServiceProxy,
    ApiServiceProxies.CashFlowReportServiceProxy,
    ApiServiceProxies.PdcReceivableReportServiceProxy,
    ApiServiceProxies.BalanceSheetReportServiceProxy,
    ApiServiceProxies.CalenderEventsServiceProxy,
    ApiServiceProxies.JournalMastersServiceProxy,
    ApiServiceProxies.EmployeeOfficeTimesServiceProxy,
    ApiServiceProxies.EmployeeOverTimesServiceProxy,
    ApiServiceProxies.ProfitLossReportServiceProxy,
    ApiServiceProxies.EmployeeBonusDeductionsServiceProxy,
    ApiServiceProxies.ReportingServiceProxy,
    ApiServiceProxies.CharacterReportServiceProxy,
    ApiServiceProxies.AccountGroupReportServiceProxy,
    ApiServiceProxies.AccountLedgerReportServiceProxy,
    ApiServiceProxies.DayBookReportServiceProxy,
    ApiServiceProxies.CashBookReportServiceProxy,
    ApiServiceProxies.OutstandingReportServiceProxy,
    ApiServiceProxies.DailyCollectionReportServiceProxy,
    ApiServiceProxies.TrailBalanceReportServiceProxy,
    ApiServiceProxies.MergeLedgerServiceProxy,
    ApiServiceProxies.StudentFeeReportServiceProxy,
    ApiServiceProxies.StudentDetailReportServiceProxy,
    ApiServiceProxies.BusAttendanceReportServiceProxy,
    ApiServiceProxies.BusStopWiseReportServiceProxy,
    ApiServiceProxies.StockReportServiceProxy,
    ApiServiceProxies.TeacherLogServiceProxy,
    ApiServiceProxies.ItemDemandsServiceProxy,
    ApiServiceProxies.MaterialSalesReportServiceProxy,


    // date
    ApiServiceProxies.DateSelectsServiceProxy,
    ApiServiceProxies.SalesMastersServiceProxy,
    ApiServiceProxies.DashboardRealDataGeneratorServiceProxy,
    ApiServiceProxies.EmployeeLoanAndAdvancesServiceProxy,
    ApiServiceProxies.SalaryVoucherMastersServiceProxy,
    ApiServiceProxies.AccountWiseLedgerReportServiceProxy,
    ApiServiceProxies.EmployeeLoanAndAdvancesServiceProxy,
    ApiServiceProxies.EmployeeSalariesServiceProxy,
    ApiServiceProxies.EmployeeLopDeductionsServiceProxy,
    ApiServiceProxies.PaymentVoucherReportServiceProxy,
    ApiServiceProxies.LedgerSummaryReportServiceProxy,
    ApiServiceProxies.ItemIssueServiceProxy,
    // transactions
    ApiServiceProxies.BankReconciliationsServiceProxy,
    ApiServiceProxies.PdcClearancesServiceProxy,
    ApiServiceProxies.PdcPayablesServiceProxy,
    ApiServiceProxies.PdcReceivablesServiceProxy,
    ApiServiceProxies.PayHeadsServiceProxy,
    ApiServiceProxies.AttendanceMastersServiceProxy,
    ApiServiceProxies.VoucherTypesServiceProxy,
    ApiServiceProxies.ContraMastersServiceProxy,
    ApiServiceProxies.AccountGroupsServiceProxy,
    ApiServiceProxies.BranchsServiceProxy,
    ApiServiceProxies.BranchsServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.ReceiptMastersServiceProxy,
    ApiServiceProxies.PaymentMastersServiceProxy,
    ApiServiceProxies.FinancialYearsServiceProxy,
    ApiServiceProxies.EmployeesServiceProxy,
    ApiServiceProxies.AccountLedgersServiceProxy,
    ApiServiceProxies.AccountGroupsServiceProxy,
    ApiServiceProxies.BranchsServiceProxy,
    ApiServiceProxies.DepartmentsServiceProxy,
    ApiServiceProxies.DesignationsServiceProxy,
    ApiServiceProxies.AuditLogServiceProxy,
    ApiServiceProxies.CachingServiceProxy,
    ApiServiceProxies.ChatServiceProxy,
    ApiServiceProxies.CommonLookupServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    ApiServiceProxies.FriendshipServiceProxy,
    ApiServiceProxies.HostSettingsServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.LanguageServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ApiServiceProxies.OrganizationUnitServiceProxy,
    ApiServiceProxies.PermissionServiceProxy,
    ApiServiceProxies.ProfileServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.TenantDashboardServiceProxy,
    ApiServiceProxies.TenantSettingsServiceProxy,
    ApiServiceProxies.VendorOrdersServiceProxy,
    ApiServiceProxies.TimingServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.UserLinkServiceProxy,
    ApiServiceProxies.UserLoginServiceProxy,
    ApiServiceProxies.WebLogServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.TenantRegistrationServiceProxy,
    ApiServiceProxies.HostDashboardServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.InvoiceServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.UiCustomizationSettingsServiceProxy,
    ApiServiceProxies.PayPalPaymentServiceProxy,
    ApiServiceProxies.StripePaymentServiceProxy,
    ApiServiceProxies.DashboardCustomizationServiceProxy,
    ApiServiceProxies.WebhookEventServiceProxy,
    ApiServiceProxies.WebhookSubscriptionServiceProxy,
    ApiServiceProxies.WebhookSendAttemptServiceProxy,
    ApiServiceProxies.UserDelegationServiceProxy,
    ApiServiceProxies.DynamicPropertyServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyServiceProxy,
    ApiServiceProxies.DynamicPropertyValueServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
    ApiServiceProxies.TwitterServiceProxy,
    ApiServiceProxies.ExamsServiceProxy,
    ApiServiceProxies.FeeGroupsServiceProxy,
    ApiServiceProxies.ItemIssueServiceProxy,

    // Academy Service Proxy
    ApiServiceProxies.GradesServiceProxy,
    ApiServiceProxies.SubjectMappingsServiceProxy,
    ApiServiceProxies.ShiftsServiceProxy,
    ApiServiceProxies.StudentSubjectMappingsServiceProxy,
    ApiServiceProxies.DivisionsServiceProxy,
    ApiServiceProxies.ProgramMasterServiceProxy,

    //studemt services
    ApiServiceProxies.StudentInfosServiceProxy,
    ApiServiceProxies.StudentAttendancesServiceProxy,
    ApiServiceProxies.StudentCategoriesServiceProxy,
    ApiServiceProxies.StudentRegistrationsServiceProxy,
    ApiServiceProxies.ParentsServiceProxy,
    ApiServiceProxies.TeacherReviewsServiceProxy,
    ApiServiceProxies.StudentAcademicUpdateServiceProxy,

    //exam services
    ApiServiceProxies.ExamMarkSetupsServiceProxy,
    ApiServiceProxies.ExamRoutinesServiceProxy,
    ApiServiceProxies.ExamMarkEntriesServiceProxy,
    ApiServiceProxies.ExamAttendancesServiceProxy,
    ApiServiceProxies.ExamSymbolsServiceProxy,
    ApiServiceProxies.ConsolidateExamsServiceProxy,
    ApiServiceProxies.SchClassesServiceProxy,
    ApiServiceProxies.SectionsServiceProxy,
    ApiServiceProxies.SubjectsServiceProxy,
    ApiServiceProxies.StudentMarkEntriesServiceProxy,
    ApiServiceProxies.ThemeMarkEntriesServiceProxy,
    ApiServiceProxies.ThemeMarkSetupServiceProxy,

    //administration
    ApiServiceProxies.ClassSchedulesServiceProxy,
    ApiServiceProxies.VisitorsServiceProxy,
    ApiServiceProxies.EmployeeApplicationsServiceProxy,
    ApiServiceProxies.GuestsServiceProxy,
    ApiServiceProxies.AwardsServiceProxy,
    ApiServiceProxies.AwardCategoriesServiceProxy,
    ApiServiceProxies.StudentAwardsServiceProxy,
    ApiServiceProxies.TeacherSubjectMappingsServiceProxy,
    ApiServiceProxies.BoarderMappingsServiceProxy,
    ApiServiceProxies.BuildingsServiceProxy,
    ApiServiceProxies.BoardersServiceProxy,
    ApiServiceProxies.PeriodsesServiceProxy,
    ApiServiceProxies.ClasssPeriodsServiceProxy,
    ApiServiceProxies.LunchsServiceProxy,
    ApiServiceProxies.LunchMappingsServiceProxy,
    ApiServiceProxies.LunchAttendancesServiceProxy,
    ApiServiceProxies.HouseMappingsServiceProxy,
    ApiServiceProxies.EnquiryFormsServiceProxy,
    ApiServiceProxies.FeedbacksServiceProxy,

    //Cms
    ApiServiceProxies.CmsFacilitiesServiceProxy,
    ApiServiceProxies.CmsDownloadsServiceProxy,
    ApiServiceProxies.CmsFrontendServiceProxy,
    ApiServiceProxies.CmsPagesServiceProxy,
    ApiServiceProxies.CmsEventsServiceProxy,
    ApiServiceProxies.CmsCourseServiceProxy,
    ApiServiceProxies.CmsVideosServiceProxy,
    ApiServiceProxies.CmsVideoCategoriesServiceProxy,
    ApiServiceProxies.ArticlesServiceProxy,
    ApiServiceProxies.NoticesServiceProxy,

    //fee
    ApiServiceProxies.FeeHeadsServiceProxy,
    ApiServiceProxies.FareFeesServiceProxy,
    ApiServiceProxies.OpeningFeesServiceProxy,
    ApiServiceProxies.OtherFeesServiceProxy,
    ApiServiceProxies.HostelFeesServiceProxy,
    ApiServiceProxies.MonthlyFeesServiceProxy,

    //library
    ApiServiceProxies.BooksServiceProxy,
    ApiServiceProxies.DocumentNumberingsServiceProxy,
    ApiServiceProxies.LibraryMembersServiceProxy,
    ApiServiceProxies.BookReceivedServiceProxy,
    ApiServiceProxies.BookIssuedServiceProxy,
    ApiServiceProxies.HouseGroupsServiceProxy,
    ApiServiceProxies.BookIssueReturnsServiceProxy,
    ApiServiceProxies.BooksSearchServiceProxy,
    ApiServiceProxies.BookDonarsServiceProxy,

    // Transportation
    ApiServiceProxies.BusMastersServiceProxy,
    ApiServiceProxies.BusStopsServiceProxy,
    ApiServiceProxies.BusTransactionMappingsServiceProxy,
    ApiServiceProxies.BusRoutinesServiceProxy,
    ApiServiceProxies.BusAttendancesServiceProxy,

    //report
    ApiServiceProxies.TaxReportServiceProxy,
   ApiServiceProxies.VatSummaryReportServiceProxy,

    //lms
    ApiServiceProxies.CourseCategoriesServiceProxy,
    ApiServiceProxies.AssignmentCategoriesServiceProxy,
    ApiServiceProxies.AssignmentsServiceProxy,
    ApiServiceProxies.BackGroundImageUploadServiceProxy,
    ApiServiceProxies.CourseUnitsServiceProxy,
    ApiServiceProxies.OnlineClassesServiceProxy,
    ApiServiceProxies.HomeWorksServiceProxy,
    ApiServiceProxies.EBooksServiceProxy,

    //examreport
    ApiServiceProxies.MarksheetReportServiceProxy,
    ApiServiceProxies.GradeLedgerReportServiceProxy,
    ApiServiceProxies.IdentityCardReportServiceProxy,
    ApiServiceProxies.AdmitCardReportServiceProxy,
    ApiServiceProxies.MarkLedgerReportServiceProxy,
    ApiServiceProxies.ExamResultPublishServiceProxy,
    ApiServiceProxies.StudentAbsentsServiceProxy,
    ApiServiceProxies.ExamRoutineReportServiceProxy,
    ApiServiceProxies.TeamMembersServiceProxy,
    ApiServiceProxies.SubjectWiseThemeMarkEntryReportServiceProxy,
    ApiServiceProxies.ThemeMarkSheetReportServiceProxy,

    // Student Proxies
    ApiServiceProxies.StudentAcademicServiceProxy,
    ApiServiceProxies.StudentExamServiceProxy,
    ApiServiceProxies.StudentFeeServiceProxy,
    ApiServiceProxies.StudentLibraryServiceProxy,
    ApiServiceProxies.StudentLmsServiceProxy,
    ApiServiceProxies.LibraryFinesServiceProxy,
    ApiServiceProxies.StudentBookHoldsServiceProxy,
    ApiServiceProxies.StudentCmsServiceProxy,
    ApiServiceProxies.QuizStudentServiceProxy,
    ApiServiceProxies.StudentReviewServiceProxy,
    ApiServiceProxies.StudentAttendanceReportServiceProxy,
    { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
    { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },

    //Teacher Proxies
    ApiServiceProxies.TeacherAcademicServiceProxy,
    ApiServiceProxies.TeacherLmsServiceProxy,
    ApiServiceProxies.TeacherCmsServiceProxy,
    ApiServiceProxies.TeacherCourseServiceProxy,
    ApiServiceProxies.TeacherMarkEntryServiceProxy,
    ApiServiceProxies.TeacherReviewsServiceProxy,
    ApiServiceProxies.TeacherStudentAttendancesServiceProxy,
    ApiServiceProxies.QuizTeacherServiceProxy,
    ApiServiceProxies.ObjectiveExamTeacherServiceProxy,
    ApiServiceProxies.TeacherOnlineClassesServiceProxy,
    ApiServiceProxies.TeacherReviewsServiceProxy,
    ApiServiceProxies.TeacherLibraryServiceProxy,
    ApiServiceProxies.TeacherOnlineMeetingServiceProxy,

    //Parents Proxies
    ApiServiceProxies.ParentFeeServiceProxy,
    ApiServiceProxies.ParentAcademicServiceProxy,
    ApiServiceProxies.ParentCmsServiceProxy,
    ApiServiceProxies.AuditLogServiceProxy,
    ApiServiceProxies.CachingServiceProxy,
    ApiServiceProxies.ChatServiceProxy,
    ApiServiceProxies.CommonLookupServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    ApiServiceProxies.FriendshipServiceProxy,
    ApiServiceProxies.HostSettingsServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.LanguageServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ApiServiceProxies.OrganizationUnitServiceProxy,
    ApiServiceProxies.PermissionServiceProxy,
    ApiServiceProxies.ProfileServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.TenantDashboardServiceProxy,
    ApiServiceProxies.TenantSettingsServiceProxy,
    ApiServiceProxies.TimingServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.UserLinkServiceProxy,
    ApiServiceProxies.UserLoginServiceProxy,
    ApiServiceProxies.WebLogServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.TenantRegistrationServiceProxy,
    ApiServiceProxies.HostDashboardServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.DemoUiComponentsServiceProxy,
    ApiServiceProxies.InvoiceServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.UiCustomizationSettingsServiceProxy,
    ApiServiceProxies.PayPalPaymentServiceProxy,
    ApiServiceProxies.StripePaymentServiceProxy,
    ApiServiceProxies.DashboardCustomizationServiceProxy,
    ApiServiceProxies.WebhookEventServiceProxy,
    ApiServiceProxies.WebhookSubscriptionServiceProxy,
    ApiServiceProxies.WebhookSendAttemptServiceProxy,
    ApiServiceProxies.UserDelegationServiceProxy,
    ApiServiceProxies.DynamicPropertyServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyServiceProxy,
    ApiServiceProxies.DynamicPropertyValueServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
    ApiServiceProxies.TwitterServiceProxy,
    { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
    { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
  ],
})
export class ServiceProxyModule { }
