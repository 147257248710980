<a (window:resize)="onResize()" [class]="customHrefClass">
  <img
    *ngIf="!appSession.tenant || (!appSession.tenant.logoId || !appSession.tenant.logoFileType)"
    [src]="defaultLogo"
    alt="Logo"
    class="h-35px app-sidebar-logo-default"
  />
  <img
    *ngIf="!appSession.tenant || (!appSession.tenant.logoId || !appSession.tenant.logoFileType)"
    [src]="defaultSmallLogo"
    alt="Logo"
    class="h-30px app-sidebar-logo-minimize"
  />
  <img
    *ngIf="appSession.tenant && (appSession.tenant.logoId || appSession.tenant.logoFileType)"
    [src]="remoteServiceBaseUrl + '/TenantCustomization/GetLogo?tenantId=' + appSession.tenant.id"
    height="38"
    alt="Logo"
    class="h-35px app-sidebar-logo-default"
  />
  <img
    *ngIf="appSession.tenant && (appSession.tenant.logoId || appSession.tenant.logoFileType)"
    [src]="remoteServiceBaseUrl + '/TenantCustomization/GetLogo?tenantId=' + appSession.tenant.id"
    height="38"
    alt="Logo"
    class="h-30px app-sidebar-logo-minimize"
  />
</a>
